<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          @finish="handleSubmit"
        >
          <a-form-item
            name="file"
            label="File"
            :rules="[{ required: true, message: 'File harap diisi' }]"
            extra="file .pdf max. 10MB"
          >
            <input type="file" name="fileInput" @change="handleFileChange" accept="application/pdf" />
          </a-form-item>

          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link :to="'/kpi/kpi-korporat/' + dateState.month + '/' + dateState.year">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const KpiKorporatUploadForm = {
  name: 'KpiKorporatUploadForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup() {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);

    const formState = reactive({
      file: null,
    });

    const dateState = reactive({
      month: params.month,
      year: params.year,
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleFileChange = e => {
      formState.file = e.target.files[0];
    };

    const handleSubmit = () => {
      const formData = new FormData();
      formData.append('file', formState.file);
      formData.append('month', dateState.month);
      formData.append('year', dateState.year);
      formData.append('kpi_korporat_id', params.id);

      dispatch('axiosDataUpload', {
        url: 'kpi-korporat-file',
        data: formData,
        redirect: '/kpi/kpi-korporat/' + dateState.month + '/' + dateState.year,
      });
    };

    return {
      formState,
      labelCol,
      wrapperCol,
      dateState,
      isLoading,
      handleSubmit,
      handleFileChange,
    };
  },
};

export default KpiKorporatUploadForm;
</script>
