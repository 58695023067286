<template>
  <KpiKorporatUploadForm mode="Upload File" module="Indikator Kinerja"> </KpiKorporatUploadForm>
</template>

<script>
import KpiKorporatUploadForm from './form-upload-kpi';

const KpiKorporatUpload = {
  name: 'KpiKorporatUpload',
  components: { KpiKorporatUploadForm },
};

export default KpiKorporatUpload;
</script>
